export const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL ?? "";
export const BACKOFFICE_BASE_URL =
  process.env.REACT_APP_BACKOFFICE_BASE_URL ?? "";
export const MAPTILER_API_KEY = process.env.REACT_APP_MAPTILER_API_KEY ?? "";
export const MAX_DISPLAYED_DEVICES =
  process.env.REACT_APP_MAX_DISPLAYED_DEVICES != null
    ? Number.parseInt(process.env.REACT_APP_MAX_DISPLAYED_DEVICES)
    : undefined;

export const AUTH_BASE_URL = process.env.REACT_APP_AUTH_BASE_URL ?? "";
export const AUTH_CLIENT_ID = process.env.REACT_APP_AUTH_CLIENT_ID ?? "";
