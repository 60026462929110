import { Skeleton, Text } from "@sencrop/ui-components";
import styled from "styled-components";

type DeviceInfoLineProps = {
  label: string;
  value?: string;
  isLoading: boolean;
  href?: string;
};
const DeviceInfoLine = (props: DeviceInfoLineProps) => {
  const { label, value, isLoading, href } = props;

  return (
    <LineWrapper>
      <Text as="span" semibold style={{ flex: "none" }}>
        {label}
      </Text>
      {isLoading ? (
        <Skeleton size="m" width={120} />
      ) : (
        <Text
          ellipsis
          title={value}
          {...(href
            ? {
                as: "a",
                target: "_blank",
                href,
                underline: true,
                variant: "branding",
              }
            : {
                as: "span",
              })}
        >
          {value ?? "-"}
        </Text>
      )}
    </LineWrapper>
  );
};

export default DeviceInfoLine;

const LineWrapper = styled.div`
  display: flex;
  flex-direction: row;
  span:not(:last-child) {
    margin-right: 0.5rem;
  }
`;
