import { color } from "@sencrop/ui-components";
import styled from "styled-components";

const Card = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${color("background-primary:10")};
  border-radius: 6px;
`;

export default Card;
