import {
  color,
  Icon,
  IconButton,
  Title,
  IconName,
} from "@sencrop/ui-components";
import styled from "styled-components";

type CardHeaderProps = {
  title: string;
  closeIcon?: IconName;
  onClose?: () => void;
};
const CardHeader = (props: CardHeaderProps) => {
  const { title, onClose, closeIcon } = props;
  return (
    <Container>
      <Title ellipsis>{title}</Title>
      {onClose ? (
        <IconButton onClick={onClose} hover>
          <Icon name={closeIcon ?? "close"} size={24} />
        </IconButton>
      ) : null}
    </Container>
  );
};

export default CardHeader;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1rem;
  border-bottom: 1px solid ${color("border-primary")};
  width: 100%;
  p {
    flex: 1;
    min-width: 0;
  }
  button {
    margin-left: 0.5rem;
  }
`;
