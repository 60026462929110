import {
  Icon,
  InputFullButtonAddon,
  InputText,
  useI18n,
} from "@sencrop/ui-components";
import { ReactNode, useState } from "react";

type InputDeviceProps = {
  onChange: (search?: string) => void;
  start: ReactNode;
};
const InputDeviceControl = (props: InputDeviceProps) => {
  const { onChange, start } = props;

  const [value, setValue] = useState<string>("");

  const { t } = useI18n();

  const handleSubmit = () => {
    onChange(value);
  };

  return (
    <InputText
      start={start}
      value={value}
      onChange={setValue}
      placeholder={t("device.placeholder")}
      noBorder
      end={
        <InputFullButtonAddon onClick={handleSubmit} disabled={!value}>
          <Icon name="send" />
        </InputFullButtonAddon>
      }
    />
  );
};

export default InputDeviceControl;
