import {
  Modal,
  ModalContent,
  ModalTitle,
  useI18n,
} from "@sencrop/ui-components";
import { ModalPortal } from "../../../components/ModalProvider";
import { useAppDispatch, useAppSelector } from "../../../store";
import { setLocale } from "../../../store/actions";
import { selectLocale } from "../../../store/selectors";
import InputLocale from "./InputLocale";

type SettingsModalProps = {
  opened: boolean;
  onClose: () => void;
};
const SettingsModal = (props: SettingsModalProps) => {
  const { opened, onClose } = props;

  const locale = useAppSelector(selectLocale);

  const dispatch = useAppDispatch();

  const { t } = useI18n();

  return (
    <ModalPortal>
      <Modal opened={opened} onClose={onClose} width={480}>
        <ModalTitle>{t("settings.title")}</ModalTitle>
        <ModalContent>
          <InputLocale
            value={locale}
            onChange={(v) => dispatch(setLocale(v))}
          />
        </ModalContent>
      </Modal>
    </ModalPortal>
  );
};

export default SettingsModal;
