import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { API_BASE_URL } from "../constants";
import AuthTokenManager from "../components/Auth/AuthTokenManager";

const withToken = setContext(async () => {
  const token = AuthTokenManager.getInstance().getToken();
  return { token };
});

const authMiddleware = new ApolloLink((operation, forward) => {
  const { token } = operation.getContext();
  if (token) {
    operation.setContext({
      headers: {
        Authorization: token ? `Bearer ${token as string}` : "",
      },
    });
  }
  return forward(operation);
});

const httpLink = new HttpLink({ uri: `${API_BASE_URL}/graphql` });

const authLink = ApolloLink.from([withToken, authMiddleware.concat(httpLink)]);

export const sencropApolloClient = new ApolloClient({
  link: authMiddleware.concat(authLink),
  cache: new InMemoryCache(),
});
