import { useEffect } from "react";
import { useAuth } from "react-oidc-context";
import { ActiveNavigator } from "./ActiveNavigator";
import { SIGN_IN_SCOPE } from "../AuthOutlet";

export const Logout = () => {
  const auth = useAuth();
  useEffect(() => {
    const logout = async () => {
      try {
        await auth.removeUser();
        await auth.signinRedirect({ scope: SIGN_IN_SCOPE });
      } catch (e) {
        console.error(e);
      }
    };
    void logout();
  }, []);

  return <ActiveNavigator />;
};
