import { BACKOFFICE_BASE_URL } from "../constants";
import { DeviceInfo } from "../models/device-info";

export const getDeviceLink = (deviceInfo?: DeviceInfo): string | undefined => {
  if (deviceInfo?.id) {
    return `${BACKOFFICE_BASE_URL}/#/Device/${String(deviceInfo?.id)}/show`;
  }
};

export const getOwnerLink = (deviceInfo?: DeviceInfo): string | undefined => {
  if (deviceInfo?.owner.id) {

    return `${BACKOFFICE_BASE_URL}/#/User/${String(deviceInfo?.owner.id)}/show`;
  }
};

export const getOrganisationLink = (
  deviceInfo?: DeviceInfo
): string | undefined => {
  if (deviceInfo?.organisation.id) {
    return `${BACKOFFICE_BASE_URL}/#/Organisation/${String(deviceInfo?.organisation.id)}/show`;
  }
};
