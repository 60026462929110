import {
  Icon,
  InputAddress,
  InputFullButtonAddon,
  useI18n,
  useSnack,
} from "@sencrop/ui-components";
import { MouseEvent, ReactNode, useEffect, useState } from "react";
import { SearchLocation } from "../../../models/search-location";
import {
  getCurrentPosition,
  reverseGeolocation,
} from "../../../utils/geolocation";

type Address = {
  line1: string;
  city: string;
  country: string;
  zip: string;
  latitude: number;
  longitude: number;
};

type InputAddressControlProps = {
  onChange: (location?: SearchLocation) => void;
  start: ReactNode;
};
const InputAddressControl = (props: InputAddressControlProps) => {
  const { onChange, start } = props;

  const [address, setAddress] = useState<Address>();

  const [isSearchingGeolocation, setIsSearchingGeolocation] =
    useState<boolean>(false);

  useEffect(() => {
    const location: SearchLocation | undefined = address
      ? {
          lat: address.latitude,
          lng: address.longitude,
        }
      : undefined;
    onChange(location);
  }, [address]);

  const { snack } = useSnack();
  const { t } = useI18n();

  const handleGeolocationClick = async (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setIsSearchingGeolocation(true);
    try {
      const geolocation = await getCurrentPosition();
      const gelocatedAddress = await reverseGeolocation(geolocation);
      setAddress(gelocatedAddress);
    } catch (err) {
      if (err instanceof GeolocationPositionError) {
        switch (err.code) {
          case err.PERMISSION_DENIED:
            snack.error({
              content: t("address.geolocation.error.permission_denied"),
            });
            break;
          case err.POSITION_UNAVAILABLE:
            snack.error({
              content: t("address.geolocation.error.position_unavailable"),
            });
            break;
          default:
            snack.error({ content: t("address.geolocation.error.internal") });
        }
      } else {
        console.error(err);
        snack.error({ content: "address.geolocation.error.internal" });
      }
    } finally {
      setIsSearchingGeolocation(false);
    }
  };

  return (
    <InputAddress
      start={start}
      value={address}
      onChange={setAddress}
      placeholder={t("address.placeholder")}
      noBorder
      end={
        <InputFullButtonAddon
          onClick={handleGeolocationClick}
          isLoading={isSearchingGeolocation}
        >
          <Icon name="my-location" />
        </InputFullButtonAddon>
      }
    />
  );
};

export default InputAddressControl;
