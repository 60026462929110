import styled from "styled-components";
import HeaderMenu from "../components/HeaderMenu";
import logo from "../../../images/logo_horizontal.svg";

const Header = () => {
  return (
    <Container>
      <Logo src={logo} />
      <HeaderMenu />
    </Container>
  );
};

export default Header;

const Container = styled.div`
  grid-area: header;
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
`;

const Logo = styled.img`
  height: 30px;
`;
