import { color } from "@sencrop/ui-components";
import { ReactNode } from "react";
import styled from "styled-components";
import Card from "./Card";

export const Page = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  background-color: ${color("background-primary:90")};
`;

export const Container = styled.div`
  width: 100%;
  max-width: 540px;
  height: auto;
`;

export const CardContent = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CardPage = ({ children }: { children: ReactNode }) => {
  return (
    <Page>
      <Container>
        <Card>
          <CardContent>{children}</CardContent>
        </Card>
      </Container>
    </Page>
  );
};
