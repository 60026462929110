import { IconName } from "@sencrop/ui-components";

export enum DeviceModel {
  RAINCROP = "raincrop",
  WINDCROP = "windcrop",
  LEAFCROP = "leafcrop",
  SOLARCROP = "solarcrop",
  DWD = "dwd",
  THERMOCROP = "thermocrop",
  SOILCROP = "soilcrop",
  DAVIS = "davis",
  MODULAR_BOARD_RAINCROP = "modular_board_raincrop",
  OTHER = "other",
}

export function getDeviceModelFromModelId(modelId?: number): DeviceModel {
  switch (modelId) {
    case 7:
      return DeviceModel.RAINCROP;
    case 8:
    case 9:
      return DeviceModel.WINDCROP;
    case 10:
      return DeviceModel.LEAFCROP;
    case 18:
      return DeviceModel.SOLARCROP;
    case 19:
      return DeviceModel.DWD;
    case 20:
      return DeviceModel.THERMOCROP;
    case 21:
      return DeviceModel.SOILCROP;
    case 22:
      return DeviceModel.DAVIS;
    case 23:
      return DeviceModel.MODULAR_BOARD_RAINCROP;
    default:
      return DeviceModel.OTHER;
  }
}

const MODELS_ICONS: Map<DeviceModel, IconName> = new Map([
  [DeviceModel.RAINCROP, "raincrop"],
  [DeviceModel.WINDCROP, "windcrop"],
  [DeviceModel.LEAFCROP, "leafcrop"],
  [DeviceModel.SOLARCROP, "sunshine"],
  [DeviceModel.THERMOCROP, "thermocrop"],
  [DeviceModel.SOILCROP, "soilcrop"],
  [DeviceModel.MODULAR_BOARD_RAINCROP, "raincrop"],
  [DeviceModel.DAVIS, "platform-davis"],
]);

export const getDeviceModelIcon = (model: DeviceModel): IconName => {
  return MODELS_ICONS.get(model) || "sencrop-icon";
};
