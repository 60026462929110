import { Icon, InputButtonAddon, media } from "@sencrop/ui-components";
import { useState } from "react";
import styled from "styled-components";
import { SearchLocation } from "../../../models/search-location";
import InputAddressControl from "./InputAddressControl";
import InputDeviceControl from "./InputDeviceControl";
import InputGpsCoordControl from "./InputGpsCoordControl";

type SearchMode = "address" | "geocoord" | "device";

type MapTopControlsProps = {
  onSearchLocationChange: (location?: SearchLocation) => void;
  onSearchDeviceChange: (value?: string) => void;
};
const MapTopControls = (props: MapTopControlsProps) => {
  const { onSearchLocationChange, onSearchDeviceChange } = props;

  const [mode, setMode] = useState<SearchMode>("address");

  const toggleMode = () =>
    setMode((currentMode) => {
      if (currentMode === "address") {
        return "geocoord";
      }
      if (currentMode === "geocoord") {
        return "device";
      }

      return "address";
    });

  return (
    <Container>
      <InputContainer>
        {mode === "address" ? (
          <InputAddressControl
            onChange={onSearchLocationChange}
            start={
              <InputButtonAddon onClick={toggleMode}>
                <Icon name="location-city-o" />
              </InputButtonAddon>
            }
          />
        ) : mode === "geocoord" ? (
          <InputGpsCoordControl
            onChange={onSearchLocationChange}
            start={
              <InputButtonAddon onClick={toggleMode}>
                <Icon name="live" />
              </InputButtonAddon>
            }
          />
        ) : (
          <InputDeviceControl
            onChange={onSearchDeviceChange}
            start={
              <InputButtonAddon onClick={toggleMode}>
                <Icon name="sencrop" />
              </InputButtonAddon>
            }
          />
        )}
      </InputContainer>
    </Container>
  );
};

export default MapTopControls;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding-right: 1rem;
`;

const InputContainer = styled.div`
  width: 100%;
  ${media.greaterThan("tablet")`
    max-width: 400px;
  `};
`;
