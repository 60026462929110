import { Map, PinMarker, PixiLayer, PixiPinMarker } from "@sencrop/ui-map";
import * as L from "leaflet";
import { useMemo, useRef, useState } from "react";
import { useQuery } from "react-query";
import styled from "styled-components";
import { getActiveDevices as getAllActiveDevices } from "../../../api/devices";
import { MAX_DISPLAYED_DEVICES } from "../../../constants";
import { Device } from "../../../models/device";
import { DeviceModel, getDeviceModelIcon } from "../../../models/device-model";
import { SearchLocation } from "../../../models/search-location";
import DeviceInfoCard from "../components/DeviceInfoCard";
import MapLoadingOverlay from "../components/MapLoadingOverlay";
import LocationCard from "../components/LocationCard";
import ZoneCircle from "../components/ZoneCircle";
import MapTopControls from "./MapTopControls";
import StatsCard from "../components/StatsCard";

const PIXI_LAYER_OPTIONS = {
  padding: 0.1,
  resolution: L.Browser.retina ? 2 : 1,
};
const LOCATION_ZOOM = 9;
const DEFAULT_CIRCLE_RADIUS = 40;
const ZOOM_TRANSFORM = (zoom: number) => {
  if (zoom < 8) return 8;
  if (zoom < 11) return zoom + 1;
  return zoom;
};

const DEFAULT_VISIBLE_MODELS = [
  DeviceModel.RAINCROP,
  DeviceModel.WINDCROP,
  DeviceModel.LEAFCROP,
  DeviceModel.SOLARCROP,
  DeviceModel.DWD,
  DeviceModel.THERMOCROP,
  DeviceModel.SOILCROP,
  DeviceModel.MODULAR_BOARD_RAINCROP,
  DeviceModel.DAVIS,
  DeviceModel.OTHER,
];

const AllDevicesMap = () => {
  const [selectedDevice, setSelectedDevice] = useState<Device>();

  const { isLoading, data: devices = [] } = useQuery(["allActiveDevices"], () =>
    getAllActiveDevices()
  );

  const handleMarkerClick = (id: number) => {
    const device = devices.find((d) => d.id === id);
    setSelectedDevice((current) => (current === device ? undefined : device));
  };

  const handleCardClose = () => {
    setSelectedDevice(undefined);
  };

  const [location, setLocation] = useState<SearchLocation>();
  const mapRef = useRef<L.Map>();

  const handleLocationChange = (l?: SearchLocation) => {
    setLocation(l);
    if (l) {
      mapRef.current?.setView(l, LOCATION_ZOOM);
    }
  };

  const handleSearchDeviceChange = (value?: string) => {
    const device = devices.find(
      (d) => String(d.id) === value || d.identification === value
    );

    if (device) {
      setLocation({ lat: device.position.lat, lng: device.position.lng });
      mapRef.current?.setView(device.position, LOCATION_ZOOM);
    }
  };

  const [circleRadius, setCircleRadius] = useState<number>(
    DEFAULT_CIRCLE_RADIUS
  );

  const [visibleModels, setVisibleModels] = useState<DeviceModel[]>(
    DEFAULT_VISIBLE_MODELS
  );

  const [filterLoading, setFilterLoading] = useState<boolean>();

  const handleToggleModel = (model: DeviceModel) => {
    setFilterLoading(true);
    setTimeout(() => {
      setVisibleModels((value) =>
        value.includes(model)
          ? value.filter((v) => v !== model)
          : [...value, model]
      );
      setFilterLoading(false);
    }, 500);
  };

  const displayedDevices = useMemo(
    () =>
      (MAX_DISPLAYED_DEVICES
        ? devices.slice(0, MAX_DISPLAYED_DEVICES)
        : devices
      ).filter((d) => visibleModels.includes(d.model)),
    [devices, visibleModels]
  );

  return (
    <Container>
      <Map
        topControls={
          !isLoading ? (
            <MapTopControls
              onSearchLocationChange={handleLocationChange}
              onSearchDeviceChange={handleSearchDeviceChange}
            />
          ) : undefined
        }
        bottomControls={
          !isLoading ? (
            <>
              {location ? (
                <LocationCard
                  location={location}
                  locationRadius={circleRadius}
                  onLocationRadiusChange={setCircleRadius}
                  devices={devices}
                />
              ) : (
                <StatsCard
                  devices={devices}
                  visibleModels={visibleModels}
                  onToggleModelClick={handleToggleModel}
                />
              )}
            </>
          ) : null
        }
        whenCreated={(map) => (mapRef.current = map)}
      >
        <PixiLayer options={PIXI_LAYER_OPTIONS} zoomTransform={ZOOM_TRANSFORM}>
          {displayedDevices.map((device) => (
            <PixiPinMarker
              position={device.position}
              id={device.id}
              key={device.id}
              selected={selectedDevice?.id === device.id}
              onClick={handleMarkerClick}
              icon={getDeviceModelIcon(device.model)}
              size={40}
            />
          ))}
        </PixiLayer>
        {location ? (
          <>
            {circleRadius ? (
              <ZoneCircle position={location} radius={circleRadius} />
            ) : null}
            ;
            <PinMarker
              position={location}
              icon="user"
              variant="position"
              size={50}
            />
          </>
        ) : null}
      </Map>
      {selectedDevice ? (
        <DeviceInfoCard device={selectedDevice} onClose={handleCardClose} />
      ) : null}
      {isLoading || filterLoading ? <MapLoadingOverlay /> : null}
    </Container>
  );
};

export default AllDevicesMap;

const Container = styled.div`
  position: relative;
  grid-area: map;
  display: flex;
  width: 100%;
  height: 100%;
`;
