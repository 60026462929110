import { ReactNode } from "react";
import ReactDOM from "react-dom";

type ModalProviderProps = {
  children: ReactNode;
};
export const ModalProvider = ({ children }: ModalProviderProps) => {
  return (
    <>
      {children}
      <div id="modal-root" />
    </>
  );
};

type ModalPortalProps = {
  children: ReactNode;
};
export const ModalPortal = ({
  children,
}: ModalPortalProps): JSX.Element | null => {
  const modalRoot = document.getElementById("modal-root");
  if (modalRoot) {
    return ReactDOM.createPortal(children, modalRoot);
  }
  return null;
};
