import preferredLocale from "preferred-locale";
import { DEFAULT_LOCALE, Locale, LOCALES, toLocale } from "./i18n";

export const getBrowserLocale = (): Locale => {
  const locale = preferredLocale([...LOCALES], DEFAULT_LOCALE, {
    regionLowerCase: false,
    languageOnly: false,
  });

  return toLocale(locale);
};