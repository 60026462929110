import { media, Text, Skeleton, useI18n } from "@sencrop/ui-components";
import { useQuery } from "react-query";
import styled from "styled-components";
import Card from "../../../components/Card";
import CardContent from "../../../components/CardContent";
import Slider from "../../../components/Slider";
import { Device } from "../../../models/device";
import { SearchLocation } from "../../../models/search-location";

const worker = new Worker(
  new URL("../../../workers/get-devices-around-center", import.meta.url)
);

function getDevicesAroundCenter(
  devices: Device[],
  center: { lat: number; lng: number },
  radius: number
): Promise<Device[]> {
  return new Promise((res) => {
    const key = (Math.random() + 1).toString(36).substring(7);
    worker.onmessage = ({ data }) => {
      if (data.key === key) {
        res(data.payload);
      }
    };
    worker.postMessage({
      key,
      payload: {
        devices,
        center,
        radius,
      },
    });
  });
}

type LocationCardProps = {
  location: SearchLocation;
  locationRadius: number;
  onLocationRadiusChange: (value: number) => void;
  devices: Device[];
};
const LocationCard = (props: LocationCardProps) => {
  const { onLocationRadiusChange, locationRadius, location, devices } = props;

  const { data: devicesInsideRadius } = useQuery<Device[]>(
    [devices.length, location.lng, location.lat, locationRadius],
    () => {
      return locationRadius > 0
        ? getDevicesAroundCenter(devices, location, locationRadius * 1000)
        : [];
    },
    {
      cacheTime: 0,
    }
  );

  const { t } = useI18n();

  return (
    <Container>
      <CardContent>
        <LocationInfo>
          {devicesInsideRadius != null ? (
            <Text size="m" align="center">
              {t("location_card.station_around_location", {
                smart_count: devicesInsideRadius.length,
              })}
            </Text>
          ) : (
            <Skeleton size="m" width={200} />
          )}
        </LocationInfo>
        <Slider
          value={locationRadius}
          onChange={onLocationRadiusChange}
          min={0}
          max={100}
          step={5}
        />
        <Text align="center" semibold>
          {t("location_card.radius", {
            smart_count: locationRadius,
          })}
        </Text>
      </CardContent>
    </Container>
  );
};

export default LocationCard;

const Container = styled(Card)`
  pointer-events: auto;
  margin: auto;
  width: 100%;
  ${media.greaterThan("tablet")`
    max-width: 400px;
  `};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LocationInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
`;
