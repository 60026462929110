import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import localForage from "localforage";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { createMigrate, persistReducer, persistStore } from "redux-persist";
import rootReducer from "./reducers";
import migrations from "./store-migrations";

localForage.config({
  name: "Sencrop Map",
  storeName: "sencrop-map",
});

const persistConfig = {
  key: "root",
  storage: localForage,
  version: 0,
  blacklist: [],
  migrate: createMigrate(migrations),
  serialize: true,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: true,
      serializableCheck: false,
      immutableCheck: false,
    }),
});

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export type AppThunk<R = void> = ThunkAction<
  R,
  RootState,
  unknown,
  Action<string>
>;

export default store;
