import { combineReducers } from "@reduxjs/toolkit";
import ui from "./ui/ui.reducer";

const reducers = {
  ui,
};

const rootReducer = combineReducers(reducers);

export default rootReducer;
