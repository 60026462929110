import { Locale as UiLocale } from "@sencrop/ui-components";
import cs from "../i18n/cs.json";
import de from "../i18n/de.json";
import en from "../i18n/en.json";
import es from "../i18n/es.json";
import fr from "../i18n/fr.json";
import hu from "../i18n/hu.json";
import it from "../i18n/it.json";
import nl from "../i18n/nl.json";

export const DEFAULT_LOCALE = "en-GB" as const;

export const LOCALES = [
  "en-GB",
  "fr-FR",
  "nl-NL",
  "es-ES",
  "de-DE",
  "cs-CZ",
  "it-IT",
  "hu-HU",
] as const;

export const LOCALES_STRING: Record<Locale, string> = {
  "fr-FR": "Français",
  "en-GB": "English",
  "es-ES": "Español",
  "de-DE": "Deutsch",
  "nl-NL": "Nederlands",
  "cs-CZ": "Čeština",
  "it-IT": "Italian",
  "hu-HU": "Magyar",
};

export type Locale = Extract<UiLocale, typeof LOCALES[number]>;

export const DICTIONARY: Record<Locale, any> = {
  "fr-FR": fr,
  "en-GB": en,
  "nl-NL": nl,
  "es-ES": es,
  "de-DE": de,
  "it-IT": it,
  "cs-CZ": cs,
  "hu-HU": hu,
};

export function isLocale(localeString: string): localeString is Locale {
  return (LOCALES as readonly string[]).includes(localeString);
}

export function toLocale(localeString: string): Locale {
  return isLocale(localeString) ? localeString : DEFAULT_LOCALE;
}
