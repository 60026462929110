import { Outlet } from "react-router-dom";
import { useAuth } from "react-oidc-context";
import React, { useEffect } from "react";
import AuthTokenManager from "./AuthTokenManager";
import { Login } from "./components/Login";
import { ActiveNavigator } from "./components/ActiveNavigator";

export const SIGN_IN_SCOPE = "openid profile email offline_access";

const AuthOutlet = () => {
  const auth = useAuth();
  useEffect(() => {
    if (!auth.isAuthenticated && !auth.isLoading) {
      auth.signinRedirect({ scope: SIGN_IN_SCOPE });
    }
  }, [auth.isAuthenticated, auth.isLoading]);

  if (auth.isAuthenticated) {
    if (auth.user?.id_token) {
      AuthTokenManager.getInstance().setToken(auth.user?.id_token);
    }
    return <Outlet />;
  }

  if (auth.activeNavigator) {
    return <ActiveNavigator />;
  }

  if (auth.error) {
    return <div>{auth.error.message}</div>;
  }

  return (
    <Login
      onClick={() => auth.signinRedirect({ scope: SIGN_IN_SCOPE })}
      loading={auth.isLoading}
    ></Login>
  );
};

export default AuthOutlet;
