import {
  BreakpointProvider,
  ConfigProvider,
  GlobalStyle,
  SnackProvider,
} from "@sencrop/ui-components";
import {QueryClient, QueryClientProvider} from "react-query";
import {Provider} from "react-redux";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import {PersistGate} from "redux-persist/integration/react";
import {ModalProvider} from "./components/ModalProvider";
import {
  AUTH_BASE_URL,
  AUTH_CLIENT_ID,
  MAPBOX_ACCESS_TOKEN,
  MAPTILER_API_KEY,
} from "./constants";
import AppI18nProvider from "./provider/AppI18nProvider";
import {AuthProvider} from "react-oidc-context";
import store, {persistor} from "./store";
import AuthOutlet from "./components/Auth/AuthOutlet";
import HomePage from "./pages/Home";
import {UserManager, WebStorageStateStore} from "oidc-client-ts";
import {Logout} from "./components/Auth/components/Logout";

// Got in documentation, you can find this implementation here https://github.com/authts/react-oidc-context/blob/f175dcba6ab09871b027d6a2f2224a17712b67c5/src/AuthProvider.tsx#L20-L30
const onSigninCallback = (): void => {
  window.history.replaceState({}, document.title, window.location.pathname);
};

const userManager: UserManager = new UserManager({
  userStore: new WebStorageStateStore({store: window.localStorage}),
  authority: AUTH_BASE_URL,
  client_id: AUTH_CLIENT_ID,
  redirect_uri: window.location.origin,
});

const queryClient = new QueryClient();

const App = () => {
  return (
    <PersistGate persistor={persistor}>
      <Provider store={store}>
        <GlobalStyle/>
        <BreakpointProvider>
          <AppI18nProvider>
            <ModalProvider>
              <SnackProvider>
                <ConfigProvider
                  mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
                  mapTilerApiKey={MAPTILER_API_KEY}
                >
                  <AuthProvider
                    userManager={userManager}
                    onSigninCallback={onSigninCallback}
                  >
                    <QueryClientProvider client={queryClient}>
                      <BrowserRouter>
                        <AppRoutes/>
                      </BrowserRouter>
                    </QueryClientProvider>
                  </AuthProvider>
                </ConfigProvider>
              </SnackProvider>
            </ModalProvider>
          </AppI18nProvider>
        </BreakpointProvider>
      </Provider>
    </PersistGate>
  );
};

export default App;

const AppRoutes = () => {
  return (
    <Routes>
      <Route element={<AuthOutlet/>}>
        <Route path="/" element={<HomePage/>}/>
        <Route path="/logout" element={<Logout/>}/>
      </Route>
      <Route path="*" element={<Navigate to="/"/>}/>
    </Routes>
  );
};
