// TODO: move me into an appropriate folder (/services ?)
class AuthTokenManager {
  private static _instance: AuthTokenManager;

  private _token: string | undefined = undefined;

  static getInstance(): AuthTokenManager {
    if (!AuthTokenManager._instance) {
      AuthTokenManager._instance = new AuthTokenManager();
    }
    return AuthTokenManager._instance;
  }

  setToken(token?: string): void {
    this._token = token;
  }

  getToken(): string | undefined {
    return this._token;
  }
}

export default AuthTokenManager;
