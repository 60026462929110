import { formatMapboxFeature } from "@sencrop/ui-components";
import type { GeocodeResponse } from "@mapbox/mapbox-sdk/services/geocoding";
import { Address } from "@sencrop/ui-components/dist/types/utils/addresses";
import { MAPBOX_ACCESS_TOKEN } from "../constants";
import ky from "ky";
import { SearchLocation } from "../models/search-location";

export async function reverseGeolocation(
  geolocation: SearchLocation
): Promise<Address | undefined> {
  const data = await ky
    .get(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${geolocation.lng},${geolocation.lat}.json`,
      {
        searchParams: new URLSearchParams({
          access_token: MAPBOX_ACCESS_TOKEN ?? "",
        }),
      }
    )
    .json<GeocodeResponse>();

  if (!data?.features?.[0] == null) {
    return undefined;
  }

  const feature = data.features[0];
  return formatMapboxFeature(feature);
}

export async function getCurrentPosition(): Promise<SearchLocation> {
  return new Promise<SearchLocation>((res, rej) => {
    navigator.geolocation.getCurrentPosition(
      (location) => {
        res({
          lat: location.coords.latitude,
          lng: location.coords.longitude,
        });
      },
      (error) => {
        rej(error);
      }
    );
  });
}