import { CardPage } from "../../Page";
import Loader from "../../Loader";

export const ActiveNavigator = () => {
  return (
    <CardPage>
      <Loader size={50} />
    </CardPage>
  );
};
