import {
  Icon,
  InputFullButtonAddon,
  InputText,
  useI18n,
} from "@sencrop/ui-components";
import { ReactNode, useState } from "react";
import { SearchLocation } from "../../../models/search-location";

const parseLatLng = (valueString: string): SearchLocation | undefined => {
  const [latString, lngString] = valueString.split(",");
  const lat = latString ? parseFloat(latString) : undefined;
  const lng = lngString ? parseFloat(lngString) : undefined;
  if (lat && !Number.isNaN(lat) && lng && !Number.isNaN(lng)) {
    return { lat, lng };
  }
  return undefined;
};

type InputGpsCoordProps = {
  onChange: (location?: SearchLocation) => void;
  start: ReactNode;
};
const InputGpsCoordControl = (props: InputGpsCoordProps) => {
  const { onChange, start } = props;

  const [value, setValue] = useState<string>("");

  const { t } = useI18n();

  const handleSubmit = () => {
    const parsed = parseLatLng(value);
    onChange(parsed);
  };

  return (
    <InputText
      start={start}
      value={value}
      onChange={setValue}
      placeholder={t("gpscoord.placeholder")}
      noBorder
      end={
        <InputFullButtonAddon
          onClick={handleSubmit}
          disabled={!parseLatLng(value)}
        >
          <Icon name="send" />
        </InputFullButtonAddon>
      }
    />
  );
};

export default InputGpsCoordControl;
