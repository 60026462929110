import { color } from "@sencrop/ui-components";
import styled from "styled-components";
import Loader from "../../../components/Loader";

const MapLoadingOverlay = () => {
  return (
    <LoadingOverlay>
      <LoaderContainer>
        <Loader size={50} />
      </LoaderContainer>
    </LoadingOverlay>
  );
};

export default MapLoadingOverlay;

const LoadingOverlay = styled.div`
  z-index: 100000;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoaderContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  background-color: ${color("background-primary:10")};
  border-radius: 6px;
`;
