import { ChangeEventHandler, ComponentPropsWithoutRef } from "react";
import styled from "styled-components";
import { color } from "@sencrop/ui-components";

type SliderOwnProps = {
  value?: number;
  onChange?: (value: number) => void;
};

type SliderProps = SliderOwnProps &
  Omit<ComponentPropsWithoutRef<"input">, keyof SliderOwnProps>;

const Slider = (props: SliderProps) => {
  const { value, onChange, ...inputProps } = props;

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const v = Number.parseInt(e.target.value);
    onChange?.(v);
  };

  return (
    <StyledInput
      type="range"
      value={value != null ? String(value) : undefined}
      onChange={handleChange}
      {...inputProps}
    />
  );
};

export default Slider;

const StyledInput = styled.input`
  -webkit-appearance: none;
  width: 100%;
  height: 12px;
  border-radius: 6px;
  background: ${color("background-primary:90")};
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;

  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: ${color("branding-primary")};
    cursor: pointer;
  }

  ::-moz-range-thumb {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: ${color("branding-primary")};
    cursor: pointer;
  }
`;
