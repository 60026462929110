import { media, useI18n } from "@sencrop/ui-components";
import { useQuery } from "react-query";
import styled from "styled-components";
import { getDeviceInfo } from "../../../api/devices";
import Card from "../../../components/Card";
import CardContent from "../../../components/CardContent";
import CardHeader from "../../../components/CardHeader";
import { Device } from "../../../models/device";
import {
  getDeviceLink,
  getOrganisationLink,
  getOwnerLink,
} from "../../../utils/backoffice-link";
import DeviceInfoLine from "./DeviceInfoLine";

type DeviceInfoCardProps = {
  device: Device;
  onClose: () => void;
};

const DeviceInfoCard = (props: DeviceInfoCardProps) => {
  const { device, onClose } = props;

  const { isLoading, data: deviceInfo } = useQuery(
    ["deviceInfo", device.id],
    () => getDeviceInfo(device.id)
  );

  const { t } = useI18n();

  return (
    <Container>
      <BottomSheetCard>
        <CardHeader
          title={deviceInfo ? deviceInfo.identification : device.identification}
          onClose={onClose}
        />
        <CardContent>
          <DeviceInfoLine
            label={t("device_info.station_name")}
            value={deviceInfo?.name || deviceInfo?.identification}
            isLoading={isLoading}
            href={getDeviceLink(deviceInfo)}
          />
          <DeviceInfoLine
            label={t("device_info.organisation")}
            value={deviceInfo?.organisation.name}
            isLoading={isLoading}
            href={getOrganisationLink(deviceInfo)}
          />
          <DeviceInfoLine
            label={t("device_info.email")}
            value={deviceInfo?.owner.email}
            isLoading={isLoading}
            href={getOwnerLink(deviceInfo)}
          />
          <DeviceInfoLine
            label={t("device_info.firstname")}
            value={deviceInfo?.owner.firstname}
            isLoading={isLoading}
          />
          <DeviceInfoLine
            label={t("device_info.lastname")}
            value={deviceInfo?.owner.lastname}
            isLoading={isLoading}
          />
          <DeviceInfoLine
            label={t("device_info.sencrop_network")}
            value={
              deviceInfo?.isInSencropNetwork
                ? t("device_info.sencrop_network.yes")
                : t("device_info.sencrop_network.no")
            }
            isLoading={isLoading}
          />
        </CardContent>
      </BottomSheetCard>
    </Container>
  );
};

export default DeviceInfoCard;

const BottomSheetCard = styled(Card)`
  margin-bottom: 2rem;
  ${media.lessThan("tablet")`
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin-bottom: 0;
  `};
`;

const Container = styled.div`
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  ${media.greaterThan("tablet")`
    max-width: 400px;
  `};
`;
