import { I18nProvider } from "@sencrop/ui-components";
import { ReactNode, useMemo } from "react";
import { useAppSelector } from "../store";
import { selectLocale } from "../store/selectors";
import { getBrowserLocale } from "../utils/browser";
import { DICTIONARY, Locale } from "../utils/i18n";

type AppI18nProviderProps = {
  children: ReactNode;
};
const AppI18nProvider = (props: AppI18nProviderProps) => {
  const { children } = props;
  const locale = useAppSelector(selectLocale);

  const appLocale = useMemo<Locale>(() => {
    return locale ?? getBrowserLocale();
  }, [locale]);

  return (
    <I18nProvider
      locale={appLocale}
      // @ts-ignore: sencrop map doesn't implement all UI locales
      dictionary={DICTIONARY}
    >
      {children}
    </I18nProvider>
  );
};

export default AppI18nProvider;
