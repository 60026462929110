import { createReducer } from "@reduxjs/toolkit";
import { Locale } from "../../utils/i18n";
import * as actions from "./ui.actions";

export type UiState = {
  locale?: Locale;
};

const initialState: UiState = {
  locale: undefined,
};

const session = createReducer(initialState, (builder) =>
  builder.addCase(actions.setLocale, (state, { payload }) => {
    state.locale = payload;
  })
);

export default session;
