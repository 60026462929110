import styled from "styled-components";
import Header from "./sections/Header";
import AllDevicesMap from "./sections/AllDevicesMap";

const HomePage = () => {
  return (
    <Container>
      <Header />
      <AllDevicesMap />
    </Container>
  );
};

export default HomePage;

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "header"
    "map";
`;
