import { Button, Title, useI18n } from "@sencrop/ui-components";
import { CardPage } from "../../Page";

interface LoginProps {
  onClick: () => void;
  loading: boolean;
}

export const Login = ({ onClick, loading }: LoginProps) => {
  const { t } = useI18n();
  return (
    <CardPage>
      <Title size="l" style={{ marginBottom: "1rem" }}>
        {loading ? t("login.loading") : t("login.welcome")}
      </Title>
      <Button isLoading={loading} size="l" full type="submit" onClick={onClick}>
        {t("login.submit")}
      </Button>
    </CardPage>
  );
};
