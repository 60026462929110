import { color, Color } from "@sencrop/ui-components";
import styled, { keyframes } from "styled-components";

type LoaderProps = {
  color?: Color;
  background?: Color;
  size?: string | number;
};
const Loader = (props: LoaderProps) => {
  const { color: colorProps, background, size } = props;
  return (
    <Leafs
      width={size}
      height={size}
      viewBox="0 0 50 50"
      $color={colorProps}
      $background={background}
    >
      <g data-leaf="3">
        <path
          d="M16.1449,45.0927 C12.1349,45.0927 9.3159,44.4457 9.1989,44.4187 L7.2699,43.9677 L8.2259,42.2337 C8.4309,41.8627 13.3429,33.0947 22.5709,28.9867 C26.2529,27.3467 30.4579,26.5157 35.0669,26.5157 C39.0779,26.5157 41.8959,27.1627 42.0139,27.1907 L43.9429,27.6407 L42.9859,29.3757 C42.7819,29.7467 37.8689,38.5137 28.6419,42.6217 C24.9599,44.2617 20.7549,45.0927 16.1449,45.0927"
          id="leaf"
        />
        <path
          d="M35.0687,25.0161 L35.0677,25.0161 C30.2457,25.0161 25.8357,25.8911 21.9607,27.6171 C12.2777,31.9271 7.1267,41.1201 6.9117,41.5101 L4.9997,44.9781 L8.8577,45.8791 C8.9827,45.9081 11.9637,46.5931 16.1447,46.5931 C20.9657,46.5931 25.3757,45.7181 29.2517,43.9931 C38.9337,39.6821 44.0857,30.4891 44.2997,30.0991 L46.2117,26.6301 L42.3547,25.7301 C42.2297,25.7011 39.2497,25.0161 35.0687,25.0161 M35.0677,28.0161 C38.9517,28.0161 41.6727,28.6511 41.6727,28.6511 C41.6727,28.6511 36.9047,37.3011 28.0307,41.2521 C23.8787,43.1011 19.5617,43.5931 16.1447,43.5931 C12.2607,43.5931 9.5397,42.9571 9.5397,42.9571 C9.5397,42.9571 14.3077,34.3081 23.1807,30.3571 C27.3337,28.5081 31.6507,28.0161 35.0677,28.0161"
          id="border"
        />
      </g>
      <g data-leaf="2">
        <path
          d="M7.806,40.5537 C7.893,40.1387 10.027,30.3187 17.651,23.6917 C25.274,17.0647 35.296,16.3177 35.719,16.2887 L37.695,16.1557 L37.287,18.0937 C37.201,18.5087 35.066,28.3287 27.443,34.9557 C19.82,41.5827 9.798,42.3297 9.375,42.3587 L7.399,42.4917 L7.806,40.5537 Z"
          id="leaf"
        />
        <path
          d="M39.5702,14.5259 L35.6172,14.7929 C35.1742,14.8229 24.6652,15.6059 16.6662,22.5599 C8.6672,29.5129 6.4292,39.8099 6.3382,40.2449 L5.5232,44.1219 L9.4762,43.8549 C9.9192,43.8249 20.4272,43.0419 28.4262,36.0879 C36.4262,29.1349 38.6642,18.8379 38.7562,18.4019 L39.5702,14.5259 Z M35.8202,17.7859 C35.8202,17.7859 33.7892,27.4519 26.4592,33.8239 C19.1282,40.1959 9.2742,40.8619 9.2742,40.8619 C9.2742,40.8619 11.3042,31.1959 18.6342,24.8239 C25.9652,18.4509 35.8202,17.7859 35.8202,17.7859 Z"
          id="border"
        />
      </g>
      <g data-leaf="1">
        <path
          d="M8.0028,38.0317 C7.9278,37.6147 6.2288,27.7097 10.8148,18.7097 C15.3998,9.7097 24.4128,5.2627 24.7938,5.0777 L26.5758,4.2137 L26.9248,6.1637 C26.9998,6.5807 28.6988,16.4857 24.1128,25.4857 C19.5278,34.4857 10.5148,38.9327 10.1338,39.1167 L8.3518,39.9817 L8.0028,38.0317 Z"
          id="leaf"
        />
        <path
          d="M27.704,2 L24.139,3.728 C23.739,3.922 14.289,8.585 9.477,18.028 C4.666,27.472 6.448,37.858 6.526,38.296 L7.224,42.195 L10.788,40.467 C11.188,40.273 20.638,35.61 25.45,26.167 C30.262,16.723 28.479,6.337 28.401,5.899 L27.704,2 Z M25.448,6.428 C25.448,6.428 27.186,16.15 22.777,24.805 C18.367,33.459 9.479,37.768 9.479,37.768 C9.479,37.768 7.741,28.045 12.151,19.391 C16.56,10.736 25.448,6.428 25.448,6.428 Z"
          id="border"
        />
      </g>
    </Leafs>
  );
};

export default Loader;

// LEAF 1 :
// KF1 (T: 1,2 sec) = P: 20,-15; R: +40; O: 0;
// KF2 (T: 1,35 sec) = P: - ; R: - ; O: 100;
// KF3 (T: 2 sec) = P: -12,-6; R: +40; O: - ;
// KF4 (T: 2,2 sec) = P: 0,0; R: +0; O: - ;

const animLeaf1 = keyframes`
  0% {
    transform: translate(20px, -15px) rotate(40deg); 
    opacity: 0; 
  }
  44% {
    transform: translate(20px, -15px) rotate(40deg); 
    opacity: 0; 
  }
  52% {
    opacity: 1; 
  }
  66% {
    transform: translate(-12px, -6px) rotate(40deg); 
  }
  77% {
    transform: translate(0, 0) rotate(0);
  }
  100% { 
    transform: translate(0, 0) rotate(0);
    opacity: 1; 
  }
`;

// LEAF 2 :
// KF1 (T: 0,4 sec) = P: 20,-20; R: +20; O: 0;
// KF2 (T: 0,55 sec) = P: - ; R: - ; O: 100;
// KF3 (T: 1,2 sec) = P: -16,-8; R: +40; O: - ;
// KF4 (T: 1,5 sec) = P: 0,0; R: +0; O: - ;

const animLeaf2 = keyframes`
  0% {
    transform: translate(20px, -20px) rotate(20deg); 
    opacity: 0; 
  }
  22% {
    transform: translate(20px, -20px) rotate(20deg); 
    opacity: 0; 
  }
  30% {
    opacity: 1; 
  }
  44% {
    transform: translate(-16px, -8px) rotate(40deg); 
  }
  61% {
    transform: translate(0, 0) rotate(0);
  }
  100% { 
    opacity: 1; 
  }
`;

// LEAF 3 :
// KF1 (T: 0) = P: 20,-25; R: 0; O: 0;
// KF2 (T: 8) = P: - ; R: - ; O: 100;
// KF3 (T: 22) = P: -20,-10; R: +50; O: - ;
// KF4 (T: 44) = P: 0,0; R: +0; O: - ;

const animLeaf3 = keyframes`
  0% {
    transform: translate(20px, -25px) rotate(0deg);
    opacity: 0; 
  }
  8% { 
    opacity: 1; 
  }
  22% { 
    transform: translate(-20px, -10px) rotate(50deg);
  }
  44% { 
    transform: translate(0, 0) rotate(0deg);
  }
  100% { 
    transform: translate(0, 0) rotate(0deg);
    opacity: 1; 
  }
`;

type LeafsProps = {
  $color?: Color;
  $background?: Color;
};
const Leafs = styled.svg<LeafsProps>`
  overflow: visible;
  g {
    path#leaf {
      fill: ${(props) => color(props.$color ?? "branding-primary")};
    }
    path#border {
      fill: ${(props) =>
        color(props.$background ?? "background-primary:10")};
    }
  }
  g {
    animation-timing-function: linear;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    transform-origin: 50% 50%;
  }
  g[data-leaf="1"] {
    animation-name: ${animLeaf1};
  }
  g[data-leaf="2"] {
    animation-name: ${animLeaf2};
  }
  g[data-leaf="3"] {
    animation-name: ${animLeaf3};
  }
`;
