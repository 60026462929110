import gql from "graphql-tag";
import { Device } from "../models/device";
import { DeviceInfo } from "../models/device-info";
import { getDeviceModelFromModelId } from "../models/device-model";
import { sencropApolloClient } from "./apollo-client";
import {
  GetAllActiveDevices,
  GetAllActiveDevices_allActiveDevices,
} from "./__generated__/GetAllActiveDevices";
import { GetDeviceInfo } from "./__generated__/GetDeviceInfo";

const SENCROP_NETWORK_ID = 5;

export const getActiveDevices = async (): Promise<Device[]> => {
  const query = gql`
    query GetAllActiveDevices {
      allActiveDevices {
        longitude
        latitude
        id
        identification
        model {
          id
        }
      }
    }
  `;

  const { data } = await sencropApolloClient.query<GetAllActiveDevices>({
    query,
  });

  const devices =
    data.allActiveDevices
      ?.filter((d): d is GetAllActiveDevices_allActiveDevices => d != null)
      .map<Device>((d) => ({
        id: parseInt(d.id),
        identification: d.identification,
        position: {
          lat: d.latitude as number,
          lng: d.longitude as number,
        },
        model: getDeviceModelFromModelId(d.model?.id),
      })) ?? [];

  return devices;
};

export const getDeviceInfo = async (id: number): Promise<DeviceInfo> => {
  const query = gql`
    query GetDeviceInfo($id: ID!) {
      Device(id: $id) {
        id
        identification
        bucketsConnection(first: 1, active: true) {
          edges {
            node {
              ownerAccesses: bucketsAccessesConnection(
                first: 1
                active: true
                type: owner
              ) {
                edges {
                  node {
                    id
                    name
                    grantee {
                      id
                      name
                      usersConnection(first: 1, type: owner) {
                        edges {
                          type
                          node {
                            email
                            firstname
                            lastname
                            id
                          }
                        }
                      }
                    }
                  }
                }
              }
              sencropNetworkAccesses: bucketsAccessesConnection(
                first: 1000
                active: true
                type: transmitter
              ) {
                edges {
                  node {
                    id
                    name
                    grantee {
                      id
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;

  const { data } = await sencropApolloClient.query<GetDeviceInfo>({
    query,
    variables: {
      id: id.toString(),
    },
  });

  if (!data.Device) throw new Error("no device found");

  const device = data.Device;
  const bucket = data.Device.bucketsConnection?.edges?.[0]?.node;
  const ownerAccess = bucket?.ownerAccesses?.edges?.[0]?.node;
  const organisation = ownerAccess?.grantee;
  const owner = organisation?.usersConnection?.edges?.[0]?.node;

  const isInSencropNetwork =
    bucket?.sencropNetworkAccesses?.edges?.some(
      (edge) => edge?.node?.grantee?.id === String(SENCROP_NETWORK_ID)
    ) ?? false;

  const info: DeviceInfo = {
    id: Number.parseInt(device.id),
    identification: device.identification,
    name: ownerAccess?.name,
    organisation: {
      id: organisation?.id ? Number.parseInt(organisation.id) : undefined,
      name: organisation?.name,
    },
    owner: {
      id: owner?.id != null ? Number.parseInt(owner.id) : undefined,
      email: owner?.email,
      firstname: owner?.firstname ?? undefined,
      lastname: owner?.lastname ?? undefined,
    },
    isInSencropNetwork,
  };
  return info;
};
