import {
  FormControl,
  FormLabel,
  InputSelect,
  useI18n,
} from "@sencrop/ui-components";
import { isLocale, Locale, LOCALES, LOCALES_STRING } from "../../../utils/i18n";

type InputLocaleProps = {
  value?: Locale;
  onChange: (value?: Locale) => void;
};
const InputLocale = (props: InputLocaleProps) => {
  const { value, onChange } = props;

  const handleChange = (v: string) => {
    onChange(isLocale(v) ? v : undefined);
  };

  const { t } = useI18n();

  return (
    <FormControl>
      <FormLabel>{t("settings.locale.label")}</FormLabel>
      <InputSelect onChange={handleChange} value={value ?? "browser"}>
        <option value="browser">{t("settings.locale.browser")}</option>
        {LOCALES.map((locale) => (
          <option key={locale} value={locale}>
            {LOCALES_STRING[locale]}
          </option>
        ))}
      </InputSelect>
    </FormControl>
  );
};

export default InputLocale;
