import { Circle } from "react-leaflet";

const COLOR_CODE = "#f96565";

type ZoneCircleProps = {
  position: L.LatLngExpression;
  radius: number; // in km
};
const ZoneCircle = (props: ZoneCircleProps) => {
  const { position, radius } = props;
  return (
    <Circle
      radius={radius * 1000}
      color={COLOR_CODE}
      center={position}
      opacity={0.6}
      interactive={false}
    />
  );
};

export default ZoneCircle;
