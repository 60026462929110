import {
  Icon,
  IconButton,
  PopoverItem,
  PopoverList,
  useI18n,
} from "@sencrop/ui-components";
import { useState } from "react";
import SettingsModal from "./SettingsModal";
import { useAuth } from "react-oidc-context";
import { AUTH_BASE_URL } from "../../../constants";
import AuthTokenManager from "../../../components/Auth/AuthTokenManager";

const HeaderMenu = () => {
  const [settingsModalOpened, setSettingsModalOpened] = useState(false);

  const { t } = useI18n();
  const auth = useAuth();

  const openSettings = () => {
    setSettingsModalOpened(true);
  };

  const closeSettings = () => {
    setSettingsModalOpened(false);
  };

  const logout = async () => {
    try {
      await auth.signoutRedirect({
        id_token_hint: AuthTokenManager.getInstance().getToken(),
        post_logout_redirect_uri: `${window.location.origin}/logout`,
      });
    } catch (e) {
      console.error(e);
    }
  };

  const identitySettings = auth.user && (
    <PopoverItem icon="account-o">
      <a href={AUTH_BASE_URL + `/ui/settings`} target="_blank">
        {auth.user.profile.email}
      </a>
    </PopoverItem>
  );

  return (
    <>
      <SettingsModal opened={settingsModalOpened} onClose={closeSettings} />
      <PopoverList
        element={
          <IconButton hover style={{ marginLeft: "0.5rem" }}>
            <Icon name="more-vert" size={24} />
          </IconButton>
        }
      >
        {identitySettings}
        <PopoverItem icon="settings-o" onClick={openSettings}>
          {t("header.settings")}
        </PopoverItem>
        <PopoverItem icon="lock-o" onClick={logout}>
          {t("header.logout")}
        </PopoverItem>
      </PopoverList>
    </>
  );
};

export default HeaderMenu;
